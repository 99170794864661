'use client'

import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/navigation'
import { sameSiteOrigins, trackEvent } from 'utils';
import styles from './ContentBlock.module.css';

const ContentBlock = ({ content }) => {
  const articleRef = useRef(null);
  const router = useRouter();

  useEffect(() => {
    if (articleRef.current) {
      articleRef.current.addEventListener('click', handleLinkClick);
    }

    return () => {
      if (articleRef.current) {
        articleRef.current.removeEventListener('click', handleLinkClick);
      }
    }
  }, [articleRef]);

  const handleLinkClick = e => {
    if (e.target.tagName === "A") {
      if (sameSiteOrigins.indexOf(e.target.origin) > -1) {
        e.preventDefault();

        const href = e.target.pathname + e.target.search

        trackEvent({
          category: 'Inbound_link--ContentBlock',
          action: 'Click',
          label: href
        });

        router.push(href)
      } else {
        trackEvent({
          category: 'Outbound_link--ContentBlock',
          action: 'Click',
          label: e.target.href
        });
      }
    }
  };

  return (
    <article 
      ref={articleRef} 
      className={styles.contentStyle} 
      dangerouslySetInnerHTML={{ __html: content }} 
    />
  )
}

export default ContentBlock;