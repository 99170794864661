'use client'

import Link from 'next/link';
import { 
  Card,
  CardHeader, 
  CardBody, 
  Heading, 
  Text,
} from '@chakra-ui/react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Waypoint } from 'react-waypoint';
import { SITE_SOCIAL_BANNER_SRC, trackEvent } from 'utils';

const ArticleCard = ({ title, slug, excerpt, featuredImgUrl, sourcePage }) => {

  const handleEnterView = () => {
    trackEvent({
      category: `ArticleCard--${sourcePage}`,
      action: "Impression",
      label: title,
    })
  }

  const handleClick = () => {
    trackEvent({
      category: `ArticleCard--${sourcePage}`,
      action: "Click",
      label: title,
    });
  }

  return (
    <Waypoint onEnter={handleEnterView}>
      <Link 
        href={`/post/${slug}`} 
        onClick={handleClick}
      >
        <Card 
          _hover={{ transition: '200ms', shadow: 'lg' }}
          className="h-full"
        >
          <CardHeader p={0}>
            <div className="w-full rounded-tl-md rounded-tr-md overflow-hidden">
              <LazyLoadImage
                src={featuredImgUrl || SITE_SOCIAL_BANNER_SRC}
                className="w-full h-32 sm:h-44 object-cover"
              />
            </div>
          </CardHeader>
          <CardBody 
            px={{ base: 2, sm: 3 }} 
            py={2}
          >
            <Heading
              as='h4'
              mb={2}
              size={{ base: 'sm', sm: 'md' }}
            >
              {title}
            </Heading>
            <Text 
              color='gray.600' 
              fontSize={{ base:'sm', sm: 'md' }}
              noOfLines={4}
            >
              {excerpt}
            </Text>
          </CardBody>
        </Card>
      </Link>
    </Waypoint>
  )
};

export default ArticleCard;