import { useState } from "react";
import { Select, Button } from '@chakra-ui/react'
import { MdSearch, MdPeople, MdPlace } from "react-icons/md";
import Link from 'next/link';
import { useRouter } from 'next/navigation'
import { renderAvailableCityOptions, renderPeopleAmountOptions, trackEvent } from 'utils';
import { AlgoliaAutocomplete } from 'src/components/common';
import styles from './HeroSearchBar.module.css';

const HeroSearchBar = props => {
  const { hotTags } = props;

  const [cityId, setCityId] = useState('');
  const [peopleAmount, setPeopleAmount] = useState('');

  const router = useRouter();

  const handleCityChange = e => {
    setCityId(e.target.value);
  };

  const handlePeopleChange = e => {
    setPeopleAmount(e.target.value);
  };

  const handleSearch = () => {
    let queryString = "";

    if (cityId) queryString = `?city=${cityId}`
    if (peopleAmount) queryString = `${queryString === "" ? "?" : queryString + "&"}pp=${peopleAmount}`

    trackEvent({ category: 'Home-Search', action: 'Search', label: queryString })
    router.push(`/games${queryString}`);
  };

  const handleTagClick = name => {
    trackEvent({ 
      category: 'Home-Tag', 
      action: 'Click', 
      label: name
    })
  };

  return (
    <div className='w-[92vw] max-w-md sm:max-w-screen-sm p-3 bg-white/95 border border-gray-200 rounded-lg shadow-xl'>
      <h2 className={styles.searchBarTitle}>
        這次，你想扮演什麼角色？
      </h2>
      <p className={styles.searchBarSlogan}>
        逃脫吧！暫別你的現實人生，在一場實境遊戲中放肆沉浸！
      </p>
      <AlgoliaAutocomplete 
        className='!w-full' 
        placeholder='快速前往：輸入遊戲主題或工作室名稱'
        source='Home-Search'
      />
      <div className='my-2 p-2 flex flex-col border-2 border-primary gap-4 rounded'>
        <p className='text-base font-medium text-neutral-700'>想找到適合的遊戲，就從城市與人數下手：</p>
        <div className='flex justify-between gap-x-4 sm:gap-x-8'>
          <div className='flex-1 flex items-center gap-x-2'>
            <MdPlace className='text-primary' size={32} />
            <Select 
              variant='outline'
              borderColor='primary.500'
              value={cityId}
              onChange={handleCityChange}
            >
              {renderAvailableCityOptions({ twinTaipei: true })}
            </Select>
          </div>
          <div className='flex-1 flex items-center gap-x-2'>
            <MdPeople className='text-primary' size={32} />
            <Select 
              variant='outline'
              borderColor='primary.500'
              value={peopleAmount}
              onChange={handlePeopleChange}
              >
              {renderPeopleAmountOptions()}
            </Select>
          </div>
        </div>
        <div className='flex justify-center'>
          <Button 
            size='md'
            colorScheme='secondary'
            w={{ base: 240 , md: 360 }}
            letterSpacing={1.2}
            leftIcon={<MdSearch size={24}/>}
            onClick={handleSearch}
          >
            搜出好遊戲
          </Button>
        </div>
      </div>
      <div className="flex items-center flex-wrap gap-2">
        <p className="text-base font-medium text-neutral-700">熱門搜尋：</p>
        {hotTags.map((tagObj) => { 
          const { name, url } = tagObj;

          return (
            <Link key={name} href={url}>
              <div
                className='p-1 bg-primary text-sm text-white font-medium rounded opacity-70 hover:opacity-100'
                onClick={() => handleTagClick(name)}
              >
                {name}
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
};

export default HeroSearchBar;