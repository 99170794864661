"use client"

import Link from 'next/link';
import { Button, Card, CardBody, VStack, Icon } from '@chakra-ui/react'
import { MdSearch } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import clsx from 'clsx';
import { default as HeroSearchBar } from './HeroSearchBar';
import { FullGameCard } from '../GamesList/FullGameCard';
import FirmCard from '../FirmsList/FirmCard';
import ArticleCard from '../PostPage/ArticleCard';
import { siteTitle, trackEvent, DEFAULT_FULL_LOGO_SRC } from 'utils';
import { useGlobal } from 'src/context';

const HomeLayout = ({ homeData }) => {
  const { hotTags, hotArticles, hotGames, newGames, hotFirms, trendingGamesByReviews, trendingGamesByLikes } = homeData;

  const { onInstall, isStandalone } = useGlobal();

  const handleInstallClick = () => {
    trackEvent({ category: 'Install_PWA--Home', action: 'Click', label: "下載 APP", value: 2 })

    onInstall();
  };

  return (
    <>
      <section>
        <h1 className='hidden'>{siteTitle}</h1>
        <div className='mb-36 md:mb-28 relative w-full h-[360px]'>
          <div className='absolute top-20 left-1/2 -translate-x-1/2 z-40'>
            <HeroSearchBar hotTags={hotTags}/>
          </div>
          <div 
            className='absolute left-0 bottom-0 md:right-0 md:top-0 w-full h-full bg-no-repeat bg-cover bg-right bg-[url("/img/home-hero-bg-2025-01.jpg")]'
            style={{ 
              backgroundAttachment: 'initial',
            }}
          />
          <div className='absolute left-0 bottom-0 md:right-0 md:top-0 w-full h-full bg-black/50 z-10' />
        </div>
        <div className='w-full md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl md:mx-auto my-4 px-2 md:px-0 pb-8 flex flex-col gap-y-8 md:gap-y-12'>
          <div className=''>
            <div className='mb-3 flex justify-between items-center border-b-2 border-primary'>
              <h3 className='text-2xl font-bold tracking-widest'>🌟 主題文章</h3>
            </div>
            <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-4'>
              {hotArticles.map(art => (
                <ArticleCard 
                  key={art.slug} 
                  sourcePage="Home"
                  {...art} 
                />
              ))}
              <MoreButton 
                type="posts"
                href="/posts" 
                label="更多主題文章" 
              />
            </div>
          </div>
          {!isStandalone && (
            <div 
              className='flex lg:hidden w-full px-4 py-2 justify-around items-center bg-black'
              onClick={handleInstallClick}
            >
              <img 
                src={DEFAULT_FULL_LOGO_SRC}
                className='w-[90px] h-[90px]'
              />
              <div>
                <p className='mb-4 text-white'>APP 低調推出</p>
                <p className='text-white'>值得每位玩家擁有</p>
              </div>
              <button className='p-3 bg-primary text-white font-black tracking-wider border-none rounded-3xl'>
                即刻安裝
              </button>
            </div>
          )}
          <div className=''>
            <div className='mb-3 border-b-2 border-primary'>
              <h3 className='text-2xl font-bold tracking-widest'>👍 本月推薦</h3>
            </div>
            <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-4 justify-items-center'>
              {hotGames.map(gameData => (
                <FullGameCard 
                  key={gameData.gameId} 
                  gameData={gameData} 
                  sourcePage="Home"
                />
              ))}
              <MoreButton 
                type="games"
                href="/games?prmo=true&outdoor=true" 
                label="更多本月推薦" 
              />
            </div>
          </div>
          <div className=''>
            <div className='mb-3 border-b-2 border-primary'>
              <h3 className='text-2xl font-bold tracking-widest'>🎉 近期新作</h3>
            </div>
            <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-4 justify-items-center'>
              {newGames.map(gameData => (
                <FullGameCard 
                  key={gameData.gameId} 
                  gameData={gameData} 
                  sourcePage="Home"
                />
              ))}
              <MoreButton 
                type="games"
                href="/games?prnew=true&outdoor=true" 
                label="更多近期新作" 
              />
            </div>
          </div>
          {(!!trendingGamesByReviews && !!trendingGamesByLikes) && (
            <div>
              <div className='mb-3 border-b-2 border-primary'>
                <h3 className='text-2xl font-bold tracking-widest'>🔥 十日熱門榜</h3>
              </div>
              <div className='px-2 grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-4'>
                <div>
                  <p className='mb-4 text-lg font-bold tracking-widest text-secondary'>評價增加最多</p>
                  <div className='flex flex-col gap-y-2'>
                    {trendingGamesByReviews.map((gameData, index) => (
                      <Link href={`/game/${gameData.gameId}`} key={`trending-reviews-${gameData.gameId}-${index}`}>
                        <div className='flex items-center justify-between'>
                          <div>
                            <span className='text-md font-bold tracking-widest'>{gameData.rank}. </span>
                            <span className='text-md font-bold tracking-widest'>{gameData.gameName}</span>
                          </div>
                          <div className='flex items-center gap-x-2'>
                            <Icon as={AiOutlinePlus} color='secondary.500' />
                            <span className='text-md font-bold tracking-widest'>{gameData.increase.toLocaleString()}</span>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div>
                  <p className='mb-4 text-lg font-bold tracking-widest text-secondary'>收藏增加最多</p>
                  <div className='flex flex-col gap-y-2'>
                    {trendingGamesByLikes.map((gameData, index) => (
                      <Link href={`/game/${gameData.gameId}`} key={`trending-likes-${gameData.gameId}-${index}`}>
                        <div className='flex items-center justify-between'>
                          <div>
                            <span className='text-md font-bold tracking-widest'>{gameData.rank}. </span>
                            <span className='text-md font-bold tracking-widest'>{gameData.gameName}</span>
                          </div>
                          <div className='flex items-center gap-x-2'> 
                            <Icon as={AiOutlinePlus} color='secondary.500' />
                            <span className='text-md font-bold tracking-widest'>{gameData.increase.toLocaleString()}</span>
                          </div>
                        </div>
                    </Link>
                  ))}
                </div>
                </div>
              </div>
            </div>
          )}
          <div className=''>
            <div className='mb-3 border-b-2 border-primary'>
              <h3 className='text-2xl font-bold tracking-widest'>👀 話題店家</h3>
            </div>
            <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-4'>
              {hotFirms.map(firmData => (
                <FirmCard 
                  key={firmData.firmId} 
                  firmData={firmData} 
                  source="Home"
                />
              ))}
              <MoreButton 
                type="firms"
                href="/firms" 
                label="更多話題店家" 
              />
            </div>
          </div>
          <FeatureSection
            title="🏮 金蛇年燈謎大會"
            imgSrc="https://storage.googleapis.com/escape-bar-firebase.appspot.com/static/lantern2025/preview-low.png"
            isLTR
          >
            <p className='leading-7'>
              實境遊戲界逃脫燈謎王～蛇我其誰！<br/>
              50道燈謎、三項稀有成就等你來挑戰<br/>
              今年已結束挑戰，來保存你的答題成績吧！
            </p>
            <Link href='/event/lantern2025'>
              <Button
                w='full'
                my={2}
                letterSpacing='wider'
                variant='solid'
                colorScheme='secondary'
                size='md'
                onClick={() => { trackEvent({ category: 'Home-Feature', action: 'Click', label: '金蛇年燈謎大會' }) }}
              >
                立刻前往猜謎
              </Button>
            </Link>
          </FeatureSection>
          <FeatureSection
            title="🔥 年度遊戲選拔"
            imgSrc="https://storage.googleapis.com/escape-bar-firebase.appspot.com/static/escapebar-goty-2023-banner-small.jpg"
          >
            <p className='leading-7'>
              第三屆年度實境遊戲選拔賽－金逃獎－<br/>
              【決戰】的時刻到來<br/>
              來看看究竟哪些遊戲獲得了這個年度殊榮
            </p>
            <Link href='/event/goty2023'>
              <Button
                w='full'
                my={2}
                letterSpacing='wider'
                variant='solid'
                colorScheme='secondary'
                size='md'
                onClick={() => { trackEvent({ category: 'Home-Feature', action: 'Click', label: '2023年度遊戲' }) }}
              >
                前往戰場
              </Button>
            </Link>
          </FeatureSection>
          <FeatureSection
            title="🧭 密室地圖"
            imgSrc="/img/home_map_background.jpg"
            isLTR
          >
            <p className='leading-7'>
              想知道你附近有哪些密室逃脫嗎？<br/>
              想安排一趟外縣市連刷不知道動線怎麼安排？<br/>
              密室地圖是你的得力助手！
            </p>
            <Link href='/escaperoommap'>
              <Button
                w='full'
                my={2}
                letterSpacing='wider'
                variant='solid'
                colorScheme='secondary'
                size='md'
                onClick={() => { trackEvent({ category: 'Home-Feature', action: 'Click', label: '密室地圖' }) }}
              >
                去看看
              </Button>
            </Link>
          </FeatureSection>
          <FeatureSection
            title="💯 打造你個人的遊戲歷程"
            imgSrc="/img/home_journey_background.jpg"
          >
            <p className='leading-7'>
              玩過了幾場密室逃脫與戶外解謎，<br/>
              想要有一個地方能夠紀錄自己的遊玩經歷？<br/>
              在逃脫吧可以輕鬆完成，<br/>
              而且分享給朋友也很容易！
            </p>
            <Link href='/post/escapers-assemble'>
              <Button
                w='full'
                my={2}
                letterSpacing='wider'
                variant='solid'
                colorScheme='secondary'
                size='md'
                onClick={() => { trackEvent({ category: 'Home-Feature', action: 'Click', label: '如何建立歷程' }) }}
              >
                了解如何建立
              </Button>
            </Link>
          </FeatureSection>
          <FeatureSection
            title="🏆 實境遊戲成就大賞"
            imgSrc="/img/home_achievements_background.jpg"
            isLTR
          >
            <p className='leading-7'>
              實境遊戲的樂趣不僅在遊戲本身，<br/>
              玩過的遊戲數量、打過招呼的店家、雙腳走過的城市也都值得紀念。<br/>
              成就大賞正式推出，<br/>
              看看有多少玩家和你一樣在這條路上瘋狂！
            </p>
            <Link href='/achievements'>
              <Button
                w='full'
                my={2}
                letterSpacing='wider'
                variant='solid'
                colorScheme='secondary'
                size='md'
                onClick={() => { trackEvent({ category: 'Home-Feature', action: 'Click', label: '如何建立歷程' }) }}
              >
                看看有哪些成就
              </Button>
            </Link>
          </FeatureSection>
        </div>
      </section>
    </>
  )
}

const FeatureSection = props => {
  const { title, imgSrc, isLTR, children } = props;

  return (
    <div className={clsx('my-4 sm:my-8 w-full flex flex-col sm:flex-row', { 'sm:flex-row-reverse': isLTR })}>
      <div 
        className='bg-cover bg-center bg-no-repeat w-full h-[150px] sm:h-[300px]'
        style={{ backgroundImage: `url(${imgSrc})`}}
      />
      <div className='w-full px-2'>
        <h4 className='p-2 text-xl font-bold tracking-widest border-b-2 border-primary'>{title}</h4>
        <div className='p-2'>
          {children}
        </div>
      </div>
    </div>
  )
};

const MoreButton = props => {
  const { type, href, label } = props;
  const isTypeGames = type === 'games'
  const cardWidth = isTypeGames ? { base: '168px', sm: '300px' } : 'full';

  return (
    <Link href={href} className={clsx({ 'w-full': !isTypeGames }, { 'w-fit': isTypeGames })}>
      <Card 
        w={cardWidth}
        maxW="100%"
        h='full'
        _hover={{ transition: '200ms', shadow: 'lg' }}
      >
        <CardBody display='flex' justifyContent='center' alignItems='center'>
          <VStack>
            <Icon as={MdSearch} w={{ base: 8, md: 12, lg: 14 }} h={{ base: 8, md: 12, lg: 14 }} color='secondary.500' />
            <p className='text-md md:text-xl font-bold tracking-wider'>
              {label}
            </p>
          </VStack>
        </CardBody>
      </Card>
    </Link>
  )
};

export default HomeLayout;