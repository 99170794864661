import { useMemo } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Waypoint } from 'react-waypoint';
import { HStack, Card, CardHeader, CardBody, Divider, Heading, Image, IconButton, Tag } from '@chakra-ui/react'
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs'
import { preferenceMap, trackEvent, DEFAULT_AVATAR_SRC } from "utils";
import { useUser, useGlobal } from 'src/context';

const colorScehmeMap = {
  escape: "blue",
  reality: "yellow",
  scenario: "green",
}

const FirmCard = props => {
  const { firmData, source = "" } = props;

  const { onOpenLogin } = useGlobal();

  const { 
    isLogin,
    user: { followingFirmsIdList },
    updateList,
  } = useUser();

  const {
    firmId = '',
    firmName = '',
    firmLogoUrl = '',
    isClosed = false,
    serviceType = [],
  } = firmData;

  const pathname = usePathname();

  const isHomePage = useMemo(() => {
    return pathname === "/"
  }, [pathname]);

  const hasFollow = useMemo(() => {
    return followingFirmsIdList.indexOf(firmId) > -1;
  }, [firmId, followingFirmsIdList]);

  const handleFollowClick = e => {
    e.preventDefault();

    if (!isLogin) {
      onOpenLogin();
      return;
    }

    trackEvent({
      category: `Follow--FirmCard--${source}`,
      action: 'Click',
      label: `${firmId}-${firmName}`,
    });

    updateList('follow', firmId);
  }

  const handleCardClick = () => {
    trackEvent({ 
      category: `FirmCard--${source}`, 
      action: 'Click', 
      label: `${firmId}-${firmName}` 
    })
  }

  const handleEnterView = () => {
    trackEvent({ 
      category: `FirmCard--${source}`, 
      action: 'Impression', 
      label: `${firmId}-${firmName}` 
    })
  };

  return (
    <Link 
      href={`/firm/${firmId}`} 
      onClick={handleCardClick}
    >
      <Waypoint onEnter={handleEnterView}>
        <Card 
          align='center' 
          w='100%' 
          position="relative" 
          _hover={{ transition: '200ms', shadow: 'lg' }}
        >
          <CardHeader py={3} align='center' w='100%' >
            <HStack>
              <Image
                boxSize={{ base: '40px', sm: '60px' }}
                objectFit='cover'
                borderRadius='full'
                boxShadow='md'
                src={firmLogoUrl}
                fallbackSrc={DEFAULT_AVATAR_SRC}
                alt={firmName}
              />
              <Heading 
                as='h5' 
                size='sm' 
              >
                {firmName}
              </Heading>
            </HStack>
            {!isHomePage && (
              <IconButton
                onClick={handleFollowClick}
                isRound
                variant='ghost'
                colorScheme='primary'
                fontSize='1.5rem'
                icon={hasFollow ? <BsBookmarkFill /> :<BsBookmark />}
                position='absolute'
                top='0'
                right='0'
                zIndex='2'
              />
            )}
          </CardHeader>
          <CardBody pt={0} pb={3} w='100%'>
            {(isClosed || serviceType.length > 0) && (
              <>
                <Divider mb={3} />
                <HStack spacing={2} justify='center' align='center'>
                  {isClosed ? (
                    <Tag 
                      size='sm' 
                      variant='solid' 
                      colorScheme='gray' 
                    >
                      已停止活動
                    </Tag>
                  ) : (
                    serviceType.map((typeKey) => {
                      return (
                        <Tag 
                          key={`type-${firmId}-${typeKey}`} 
                          size='sm' 
                          variant='outline' 
                          colorScheme={colorScehmeMap[typeKey]}
                        >
                          {preferenceMap[typeKey.toUpperCase()]}
                        </Tag>
                      );
                    })
                  )}
                </HStack>
              </>
            )}
          </CardBody>
        </Card>
      </Waypoint>
    </Link>
  )
}

export default FirmCard;