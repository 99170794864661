"use client"

import { useToast } from '@chakra-ui/react'
import { FacebookShareButton, LineShareButton } from 'react-share';
import clsx from 'clsx'
import { siteTitle, trackEvent, getDocumentTitle, getDocumentUrl } from 'utils';

const SHOW_SNACKBAR_DURATION = 1400;
const viewPortSize = '36px';

const ShareFab = props => {
  const { sourcePage = '', trackTag = '', isScreenLgOnly = false } = props;

  const toast = useToast()

  const title = getDocumentTitle();
  const shareUrl = getDocumentUrl();

  const trackSharing = (action, label) => {
    trackEvent({ 
      category: `Sharing--${sourcePage}`, 
      action, 
      label, 
      value: 1
    })
  };

  // const handleLineShare = () => {
  //   trackSharing('Line_fab', trackTag)
    
  //   if(!isScreenLg) {
  //     window.location.href = `line://msg/text/${title}${shareUrl}?utm_source=line&utm_medium=social&utm_campaign=fab`;
  //   }
  // };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(`${shareUrl}?utm_source=random&utm_medium=social&utm_campaign=fab`)

      toast({
        title: '已複製網址',
        position: 'top',
        status: 'success',
        duration: SHOW_SNACKBAR_DURATION,
      })

      trackSharing('Copy_fab', trackTag);
    } catch (err) {
      console.log(err);
    }
  };

  const handleShareClick = async () => {
    if (navigator.share) {
      const shareData = {
        title: title,
        text: '逃脫吧 - Escapebar',
        url: shareUrl
      }

      try {
        await navigator.share(shareData)

        trackSharing('NativeShare_fab', trackTag);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className={clsx('flex gap-4 items-center', { 'hidden lg:flex' : isScreenLgOnly })}>
      <p className='font-medium'>分享至社群</p>

      <div
        className="w-9 h-9 rounded-full text-center opacity-80 hover:opacity-100 cursor-pointer"
        onClick={() => trackSharing('Facebook_fab', trackTag)}
      >
        <FacebookShareButton
          url={`${shareUrl}?utm_source=facebook&utm_medium=social&utm_campaign=fab`}
          quote={`${title} | ${siteTitle}`}
          hashtag={`#逃脫吧 #Escapebar #密室逃脫`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={viewPortSize} height={viewPortSize}>
            <path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z" />
            <path fill="#fff" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z" />
          </svg>
        </FacebookShareButton>
      </div>

      <div 
        className="w-9 h-9 rounded-full text-center opacity-80 hover:opacity-100 cursor-pointer"
        onClick={() => trackSharing('Line_fab', trackTag)}
      >
          <LineShareButton
            url={`${shareUrl}?utm_source=line&utm_medium=social&utm_campaign=fab`}
            title={title}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={viewPortSize} height={viewPortSize}>
              <path fill="#00c300" d="M12.5,42h23c3.59,0,6.5-2.91,6.5-6.5v-23C42,8.91,39.09,6,35.5,6h-23C8.91,6,6,8.91,6,12.5v23C6,39.09,8.91,42,12.5,42z" />
              <path fill="#fff" d="M37.113,22.417c0-5.865-5.88-10.637-13.107-10.637s-13.108,4.772-13.108,10.637c0,5.258,4.663,9.662,10.962,10.495c0.427,0.092,1.008,0.282,1.155,0.646c0.132,0.331,0.086,0.85,0.042,1.185c0,0-0.153,0.925-0.187,1.122c-0.057,0.331-0.263,1.296,1.135,0.707c1.399-0.589,7.548-4.445,10.298-7.611h-0.001C36.203,26.879,37.113,24.764,37.113,22.417z M18.875,25.907h-2.604c-0.379,0-0.687-0.308-0.687-0.688V20.01c0-0.379,0.308-0.687,0.687-0.687c0.379,0,0.687,0.308,0.687,0.687v4.521h1.917c0.379,0,0.687,0.308,0.687,0.687C19.562,25.598,19.254,25.907,18.875,25.907z M21.568,25.219c0,0.379-0.308,0.688-0.687,0.688s-0.687-0.308-0.687-0.688V20.01c0-0.379,0.308-0.687,0.687-0.687s0.687,0.308,0.687,0.687V25.219z M27.838,25.219c0,0.297-0.188,0.559-0.47,0.652c-0.071,0.024-0.145,0.036-0.218,0.036c-0.215,0-0.42-0.103-0.549-0.275l-2.669-3.635v3.222c0,0.379-0.308,0.688-0.688,0.688c-0.379,0-0.688-0.308-0.688-0.688V20.01c0-0.296,0.189-0.558,0.47-0.652c0.071-0.024,0.144-0.035,0.218-0.035c0.214,0,0.42,0.103,0.549,0.275l2.67,3.635V20.01c0-0.379,0.309-0.687,0.688-0.687c0.379,0,0.687,0.308,0.687,0.687V25.219z M32.052,21.927c0.379,0,0.688,0.308,0.688,0.688c0,0.379-0.308,0.687-0.688,0.687h-1.917v1.23h1.917c0.379,0,0.688,0.308,0.688,0.687c0,0.379-0.309,0.688-0.688,0.688h-2.604c-0.378,0-0.687-0.308-0.687-0.688v-2.603c0-0.001,0-0.001,0-0.001c0,0,0-0.001,0-0.001v-2.601c0-0.001,0-0.001,0-0.002c0-0.379,0.308-0.687,0.687-0.687h2.604c0.379,0,0.688,0.308,0.688,0.687s-0.308,0.687-0.688,0.687h-1.917v1.23H32.052z" />
            </svg>
          </LineShareButton>
      </div>
      <div className="w-9 h-9 rounded-full text-center opacity-80 hover:opacity-100 cursor-pointer">
        <button 
          className='w-full h-full' 
          onClick={handleCopyLink}
        >
          <img 
            className="w-8"
            src="/img/copy_button.png" 
          />
        </button>
      </div>
    </div>
  )
}

export default ShareFab